import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Link,
  Button,
} from "@mui/material";

export default function WelcomeDialog(props) {
  const { dialog, showDialog } = props;
  return (
    <Dialog
      data-testid='dialog'
      open={dialog}
      style={{ padding: "4rem" }}
      onClick={() => showDialog(false)}>
      <DialogTitle id='alert-dialog-title'>
        {"XCP network HTML5 card creator by burstMembrane"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' display='block'>
          This utility is designed to help make enhanced audio NFTs to host
          custom sound card assets on the counterparty network for display on{" "}
          <Link href='https://xchain.io' color='inherit'>
            https://xchain.io
          </Link>{" "}
          and in{" "}
          <Link href='https://freewallet.io/' color='inherit'>
            FreeWallet{" "}
          </Link>
          Please note that this tool is currently in an experimental beta state.
          Use at your own risk! For enquiries or help please contact
          <Link color='inherit' href='mailto:liam.power@protonmail.com'>
            {" "}
            liam.power@protonmail.com
          </Link>
          <hr></hr>
          <strong>
            {" "}
            Please note due to increasingly high server hosting costs for large
            files the upload function to fakeasf.club has been disabled. If you
            want to make an enhanced card, you will have to upload your own
            audio and video files and get links before using this generator.{" "}
          </strong>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => showDialog(false)}
          autoFocus
          variant='success'
          color='success'>
          I get it!
        </Button>
      </DialogActions>
    </Dialog>
  );
}
