import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import HelpIcon from "@mui/icons-material/Help";

import { useState } from "react";
import FAQ from "../instructions/FAQ";
export default function Nav(props) {
  const [about, setAbout] = useState(false);
  const [faq, setFAQ] = useState(false);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static'>
        <Toolbar>
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='menu'
            sx={{ mr: 2 }}></IconButton>
          <Typography
            onClick={() => setAbout(true)}
            variant='h6'
            component='div'
            sx={{ flexGrow: 1 }}>
            enhanced.cards: a flexible JSON generator for counterparty NFTs.
          </Typography>
          {faq && <FAQ open={faq} setFAQ={setFAQ}></FAQ>}
          <Button
            startIcon={<HelpIcon></HelpIcon>}
            onClick={() => setFAQ(true)}
            color='inherit'>
            FAQ
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
