import {
  Typography,
  Divider,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";

export default function FAQ(props) {
  return (
    <Dialog
      open={props.open}
      style={{ padding: "4rem" }}
      onClose={() => props.setFAQ(false)}>
      <DialogTitle id='scroll-dialog-title'>FAQ</DialogTitle>
      <DialogContent>
        <Typography>
          <strong>What does this tool do? </strong>
          <p>
            {" "}
            This tool creates audio and video NFTs for use on the counterparty
            network. It does this by creating a JSON file which points
            counterparty block exploreres such as freewallet and xchain to video
            and audio assets which are hosted by you
            <br />
            An example of the kind of asset made through this tool can be found
            here:
            <br />
            <Link color='inherit' href='https://xchain.io/asset/FAKEACOUSTIC'>
              FAKEACOUSTIC
            </Link>
          </p>
        </Typography>
        <Divider />
        <Typography>
          <strong> What is a JSON? </strong>
          <p>
            JSON is short for Javascript Object Notation, in the context of
            counterparty NFTs it's used as a way of passing data about where
            your files are stored between websites. For more info go here:
            <br />
            <Link color='inherit'>https://en.wikipedia.org/wiki/JSON</Link>
          </p>
        </Typography>
        <Typography>
          <strong> Can this tool create my asset for me? </strong>
          <p>
            This tool generates a reference that can be used in your asset
            description. You need to create an asset yourself in freewallet or
            counterwallet and put the link to the JSON file generated by this
            tool into the description.
          </p>
        </Typography>
        <Typography>
          <strong> My audio doesn't play automatically! </strong>
          <p>
            As a security measure, most modern browsers stop audio from playing
            automatically when a user views a website. This stops popups and
            random spam websites from playing audio through your
            headphones/speakers as you're browsing the web. For most browsers, a
            user "interaction" in the form of clicking a play button is required
            before a webpage is allowed to play audio or video.
            <br />
            <Link color='inherit'>
              https://developer.chrome.com/blog/autoplay/
            </Link>
          </p>
        </Typography>
        <Typography>
          <strong> It isn't working! Can you make my card for me? </strong>
          <p>
            {" "}
            Before asking for help, please try and run through the form and see
            if you can make the card yourself. The time that I spend helping you
            make your card could be spent on making this site better! Please be
            mindful of this.{" "}
          </p>
        </Typography>

        <Typography>
          <strong>
            {" "}
            I don't want to host my JSON file on your server. What if it goes
            down?{" "}
          </strong>
          <p>
            While every effort has been made to ensure the longevity of assets
            made through this system, the responsibility is ultimately on you
            for the long-term storage and permanence of your nfts. If you are
            worried about this, I would suggest looking into hosting your assets
            using a service such as{" "}
            <Link color='inherit' href='https://www.arweave.org/'>
              Arweave
            </Link>
          </p>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
