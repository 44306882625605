import { Typography } from "@mui/material";
import styled from "styled-components";

export const CardImage = styled.img`
  position: absolute;
  max-width: 400px;
  max-height: 560px;
  z-index: 0;
  min-width: 400px;
  min-height: 560px;
`;
export const Card = styled.div`
  z-index: -1;
  background-color: #212121;
  background-size: cover;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: column;
`;

export const PlaceHolder = styled(Typography)`
  font-size: 12rem;
  font-weight: 500;
  align-self: center;
  justify-self: center;
  margin: auto 0;
  color: rgba(255, 255, 255, 0.02);
`;

export const Controls = styled.div`
  z-index: 12;
  transition: opacity 0.3s;
  opacity: 1;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0 1rem;
  width: 100%;

  background: rgba(0, 0, 0, 0.7);
  background: -webkit-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0)
  );
  background: -moz-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.05)); */

  &:hover {
    opacity: 1;
    transition: opacity 0.3s;
  }
`;

export const Button = styled.button`
  background: none;
  transition: opacity 0.3s;
  opacity: 0.2;

  border: 2px solid #fff;

  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  color: #fff;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 1;
    transition: opacity 0.2s;
  }
`;
export const PlayButton = styled(Button)``;

export const SeekBarContainer = styled.div`
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  p {
    opacity: 0.8;
    font-size: 0.825rem;
    user-select: none;
  }
`;
export const SeekBar = styled.progress`
  width: 100%;
  padding: 1px;

  height: 3px;

  border: none;
  margin-right: 0.5rem;
  color: rgba(255, 255, 255, 0.3);

  /* border-radius: 5px; */
  outline: none;
  &[value] {
    -webkit-appearance: none;
    appearance: none;
  }
  &[value]::-webkit-progress-bar {
    border-radius: 3px;
    height: 2px;
    border: none;
    box-shadow: none;
    outline: none;
    background-color: rgba(255, 255, 255, 0.2);
  }
  &::-webkit-progress-value {
    background: rgba(255, 255, 255, 0.5);
  }
`;
