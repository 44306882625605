import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Box, Typography, Link } from "@mui/material";

export default function JSONInstructions({
  jsonLink,
  json,
  previewLink,
  name,
}) {
  return (
    <div>
      <Box m={2}>
        <Typography display='block' gutterBottom>
          <strong>Generated JSON successfully!</strong>
          <br></br>
          Your link is: <br></br>
        </Typography>
        <Link color='inherit' target='_blank' href={jsonLink}>
          {jsonLink}
        </Link>{" "}
        <br></br>
        <strong>View a preview of your asset here</strong>
        <br></br>
        <Link color='inherit' target='_blank' href={previewLink} gutterBottom>
          {previewLink}
        </Link>{" "}
        <br></br>
        Here is your generated JSON.
        <br></br>
        <strong>
          Please check the image links to make sure they are all working
          correctly.{" "}
        </strong>
        <SyntaxHighlighter
          sx={{ minHeight: "500px" }}
          language='javascript'
          style={vscDarkPlus}>
          {JSON.stringify(json, null, 2)}
        </SyntaxHighlighter>
        <Box m={2} />
        <Typography>
          Paste  {jsonLink} into your your asset description in
          Counterwallet (Freewallet won't let you have a long enough description
          for the full url) and create the asset. <br></br>{" "}
          <strong> NOTE: </strong>
          Please paste the whole link with the https:// at the start and the
          .json at the end! Xchain won't be able to read your card data
          otherwise.
          <br></br>
          If you found this tool useful send me your card or a donation so I can
          keep developing! My BTC address is 14sxc2ZaGcPC51hfYksSVmQm9YUt5HVkiK
        </Typography>
        <Typography display='block' gutterBottom></Typography>
      </Box>
    </div>
  );
}
