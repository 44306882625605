import regeneratorRuntime from "regenerator-runtime";
import { S3 } from "aws-sdk";

class AWSUploader {
  constructor() {
    console.log("AWSInstance Loaded");
    // create an S3 Instance
    this.s3 = new S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, // accessKeyId that is stored in .env file
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY_SECRET,
      region: "us-west-1",
    });
    this.directoryKey = null;
  }
  async setDirectory(directoryName) {
    return new Promise(async (res, rej) => {
      this.prefix = process.env.REACT_APP_PREFIX + "/" + directoryName;
      console.log(`Prefix: ${this.prefix}`);
      const inBucket = await this.s3.listObjectsV2(
        {
          Bucket: "fakeasf.club",
          Prefix: this.prefix,
        },

        (err, data) => {
          if (err) {
            console.error(err);
          }
          this.keys = data;
          console.log(data);
          if (data.Contents.length > 1) {
            console.error(
              `${directoryName} is taken. Please choose another asset name.`
            );
            rej(`${directoryName} is taken. Please choose another asset name.`);
          } else {
            console.log("Key Does not exist in bucket");
            this.directoryKey = `${this.prefix}/`;
            res(this.directoryKey);
          }
        }
      );
    });
  }
  validateSize(file, width, height) {
    return new Promise((resolve, reject) => {
      const filename = file.name;
      const tempImg = new Image();
      const imageURL = URL.createObjectURL(file);

      tempImg.src = imageURL;
      tempImg.onload = () => {
        if (tempImg.width !== width || tempImg.height !== height) {
          reject(`Image ${filename} is wrong size`);
        } else {
          resolve(true);
        }
        console.log(
          `image ${filename} w: ${tempImg.width} h: ${tempImg.height}`
        );
      };
    });
  }

  toArrayBuffer(file) {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        var buf = new Uint8Array(reader.result);
        console.log(buf);
        res(buf);
      };
      reader.onerror = (e) => {
        rej(e);
      };
    });
  }
  uploadImage(filename, imageArrayBuffer, type, logo = false) {
    console.log(`Uploading file ${filename} to AWS Bucket`);

    return new Promise(async (res, rej) => {
      console.log(`Uploading image ${filename} to AWS Bucket...`);
      const params = {
        Body: imageArrayBuffer,
        Bucket: "fakeasf.club",
        Key: `${this.directoryKey}${filename}`,
        ACL: "public-read",
        ContentType: type,
      };
      if (!this.directoryKey) {
        rej("directory key already in bucket... returning");
      }
      // working url
      const url = `https://fakeasf.club/${params.Key}`;
      this.s3.upload(params, (err, data) => {
        if (err) {
          rej(err);
        } else {
          console.log(data);
          res(url);
        }
      });
    });
  }

  uploadAudio(filename, file, type) {
    return new Promise(async (res, rej) => {
      console.log(`Uploading audio ${filename} to fakeasf.club...`);
      const params = {
        Body: file,
        Bucket: "fakeasf.club",
        Key: `${this.directoryKey}${filename}`,
        ACL: "public-read",
        ContentType: type,
      };
      if (!this.directoryKey) {
        rej("directory key already in bucket... returning");
      }
      // working url
      const url = `https://fakeasf.club/${params.Key}`;
      this.s3.upload(params, (err, data) => {
        if (err) {
          rej(err);
        } else {
          console.log(data);
          res(url);
        }
      });
    });
  }

  uploadVideo(filename, file, type) {
    return new Promise(async (res, rej) => {
      console.log(`Uploading video ${filename} to fakeasf.club...`);
      const params = {
        Body: file,
        Bucket: "fakeasf.club",
        Key: `${this.directoryKey}${filename}`,
        ACL: "public-read",
        ContentType: type,
      };
      if (!this.directoryKey) {
        rej("directory key already in bucket... returning");
      }
      // working url
      const url = `https://fakeasf.club/${params.Key}`;
      this.s3.upload(params, (err, data) => {
        if (err) {
          rej(err);
        } else {
          console.log(data);
          res(url);
        }
      });
    });
  }
  uploadHTML(filename, html, type) {
    return new Promise(async (res, rej) => {
      console.log(`Uploading audio ${filename} to fakeasf.club...`);
      const params = {
        Body: html,
        Bucket: "fakeasf.club",
        Key: `${this.directoryKey}${filename}`,
        ACL: "public-read",
        ContentType: type,
      };
      if (!this.directoryKey) {
        rej("directory key already in bucket... returning");
      }
      // working url
      const url = `https://fakeasf.club/${params.Key}`;
      this.s3.upload(params, (err, data) => {
        if (err) {
          rej(err);
        } else {
          console.log(data);
          res(url);
        }
      });
    });
  }

  uploadJSON(filename, data) {
    return new Promise((res, rej) => {
      console.log(`Uploading JSON ${filename} to AWS Bucket...`);
      console.log(data);
      if (!this.directoryKey) {
        rej("directory key already in bucket... returning");
      }
      const params = {
        Body: data,
        Bucket: "fakeasf.club",
        Key: `${this.directoryKey}${filename}`,
        ACL: "public-read",
        ContentType: "application/json",
      };
      const url = `https://${params.Bucket}/${params.Key}`;
      this.s3.upload(params, (err, data) => {
        if (err) {
          rej(err);
        } else {
          console.log(data.Location);
          res(url);
        }
      });
    });
  }
}

export default AWSUploader;
