// absolute imports
import {
  Paper,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Box,
  Link,
  Typography,
  Collapse,
  Skeleton,
  InputLabel,
  NativeSelect,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import WAValidator from "@swyftx/api-crypto-address-validator/dist/wallet-address-validator.min.js";
import { useState } from "react";
import styled from "styled-components";
import AWSUploader from "./utils/AWSUploader";
// relative imports
import { Template } from "./utils/Template";
import WelcomeDialog from "./instructions/WelcomeDialog";
import AssetInstructions from "./instructions/AssetInstructions";
import JSONInstructions from "./instructions/JSONInstructions";
import CardPreview from "./CardPreview";
import UploadInput from "./UploadInput";
import { Label } from "@mui/icons-material";
const UploadForm = styled.div``;

const StyledTextField = styled(TextField)`
  margin-bottom: 0.5rem;
`;

// TODO: upload generated html to s3/firebase and hsot without base64 encoding. (this will allow updates later)
// TODO: add react-s3
export default function CardForm({ showError, showSuccess, setMessage }) {
  const [dialog, showDialog] = useState(true);
  const [json, setJSON] = useState(null);
  const [html, setHTML] = useState(null);
  const [selfHosted, setSelfHosted] = useState(true);
  const [uploaded, setUploaded] = useState(false);
  const [soundInput, setSoundInput] = useState("");

  const [soundFile, setSoundFile] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [iconFile, setIconFile] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [soundPreview, setSoundPreview] = useState("");
  const [videoPreview, setVideoPreview] = useState("");
  const [preview, setPreview] = useState(false);

  //  VIDEO STUFF
  const [videoInput, setVideoInput] = useState("");
  const [videoCard, setVideoCard] = useState(true);
  const [videoFile, setVideoFile] = useState("");

  // FORM FIELDS
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [supply, setSupply] = useState("");
  const [icon, setIcon] = useState("");
  const [jsonLink, setJsonLink] = useState("");
  const [previewLink, setPreviewLink] = useState("");
  const [uploading, setUploading] = useState(false);

  const handleVideoURL = async (url) => {
    console.log("handling video");
    if (selfHosted) {
      checkElement(url, setVideoFile, "video");
      setVideoPreview(url);
    } else {
      checkElement(url, setVideoPreview, "video");
    }
  };
  const handleSelfHosted = async (e) => {
    if (name === "") {
      showError({ message: "Please enter an Asset Name" });

      return;
    }

    if (
      !imageFile.name || !videoCard
        ? !soundFile.name
        : !videoFile.name || !iconFile.name
    ) {
      console.dir(imageFile);
      setSoundInput(e.target.value);
      console.dir(soundFile);
      console.dir(iconFile);
      showError({ message: "Please fill out fields before uploading" });
      return;
    }
    const uploader = new AWSUploader();

    try {
      await uploader.setDirectory(name);
    } catch (error) {
      showError({ message: error });
      return;
    }
    const mediaFile = videoCard ? videoFile : soundFile;
    const [base, ext] = imageFile.name.split(".");

    const [mediaBase, mediaExt] = mediaFile.name.split(".");

    const iconArr = await uploader.toArrayBuffer(iconFile);
    const imageArr = await uploader.toArrayBuffer(imageFile);
    const mediaArr = await uploader.toArrayBuffer(mediaFile);
    try {
      setUploading(true);
      const iconRes = await uploader.uploadImage(
        `${name}-48x48.png`,
        iconArr,
        iconFile.type,
        true
      );
      const imageRes = await uploader.uploadImage(
        `${name}.${ext}`,
        imageArr,
        imageFile.type,
        false
      );
      setIcon(iconRes);
      setImageFile(imageRes);
      if (!videoCard) {
        const audioRes = await uploader.uploadAudio(
          `${name}.${mediaExt}`,
          mediaArr,
          soundFile.type,
          false
        );
        setSoundFile(audioRes);
        // upload HTML for hosting on servers
        const template = new Template({
          audioRes,
          imageRes,
          icon,
          name,
          supply,
          address,
          videoUrl: false,
        });

        console.log(audioRes);

        const html = template.makeHTML(audioRes, imageRes);
        const htmlRes = await uploader.uploadHTML(
          `index.html`,
          html,
          "text/html"
        );
        setHTML(htmlRes);
        setUploaded(true);
        setUploading(false);
      } else {
        const videoRes = await uploader.uploadVideo(
          `${name}.${mediaExt}`,
          mediaArr,
          videoFile.type,
          false
        );
        setVideoFile(videoRes);

        console.log(videoRes);

        const template = new Template({
          soundFile: null,
          imageRes,
          icon,
          name,
          supply,
          address,
          videoUrl: videoRes,
        });

        const html = template.makeVideoTemplate(videoRes);
        // const htmlRes = await uploader.uploadHTML(
        //   `index.html`,
        //   html,
        //   "text/html"
        // );
        setHTML(html);
        setUploaded(true);
        setUploading(false);
      }
    } catch (error) {
      setUploading(false);
      showError(error);
      return;
    }

    // console.log("selfHosted!");

    showSuccess("Uploaded files successfully");
    setUploading(false);
  };
  const generateJSON = async () => {
    console.log(`Generating JSON with values:`);
    console.log(`Asset Name: ${name}`);
    console.log(`Address: ${name}`);
    console.log(`Icon url: ${iconFile}`);
    console.log(`Image url: ${imageFile}`);
    console.log(`Audio url: ${soundFile}`);
    console.log(`Video url: ${videoFile}`);
    console.log(`HTML url: ${html}`);

    setUploading(true);
    console.log("Generating HTML");
    const uploadLink = `https://json.enhanced.cards/upload/${name}.json`;
    const dataLink = `https://json.enhanced.cards/data/${name}.json`;
    setPreviewLink(`https://json.enhanced.cards/preview/${name}.json`);

    const userTemplate = new Template({
      soundFile,
      imageFile,
      icon,
      name,
      supply,
      address,
      videoUrl: videoFile,
    });
    console.log(userTemplate.makeJSON(html));
    try {
      const uploadReq = await fetch(uploadLink, {
        method: "POST",
        body: JSON.stringify(userTemplate.makeJSON(html)),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const resJSON = await uploadReq.json();
      if (resJSON.error) {
        showError({
          message: resJSON.error,
        });
        setUploading(false);
        return;
      } else if (resJSON) {
        setJSON(resJSON);
        showSuccess(`JSON file uploaded successfully!`);
        setJsonLink(dataLink);
        setUploading(false);
      } else {
        showError({
          message: `Failed to upload ${name}.json to firebase server.`,
        });
        setUploading(false);
      }
    } catch (error) {
      showError(error);
    }
  };

  const selfHostedForm = () => {
    return (
      <>
        <Typography>
          {" "}
          Paste the links to your image and {videoCard ? "video" : "audio"}{" "}
          files below
        </Typography>
        <StyledTextField
          hidden={!selfHosted}
          required={selfHosted}
          onChange={(e) => {
            handleIconUrl(e.target.value);
          }}
          id='icon-url'
          label='Icon URL: 48x48 .png e.g https://i.imgur.com/ajdiwda.png'
          variant='standard'
        />
        <StyledTextField
          onChange={(e) => {
            handleImageURL(e.target.value);
            // setImageFile(e.target.value);
          }}
          required={selfHosted}
          id='image-url'
          label='Image URL: gif, png, jpeg. e.g https://i.imgur.com/ajdiwda.gif'
          variant='standard'
        />
        {!videoCard ? (
          <StyledTextField
            required={selfHosted}
            value={soundInput}
            onChange={(e) => {
              setSoundInput(e.target.value);
              handleAudioURL(e.target.value);
            }}
            id='audio-url'
            label='Audio URL: mp3, aac, wav e.g https://somehost.com/sound.mp3'
            variant='standard'
          />
        ) : (
          <StyledTextField
            required={selfHosted}
            value={videoInput}
            onChange={(e) => {
              setVideoInput(e.target.value);
              handleVideoURL(e.target.value);
            }}
            id='video-url'
            label='Video URL: mp4 e.g https://somehost.com/video.mp4'
            variant='standard'
          />
        )}
      </>
    );
  };

  const uploadForm = () => {
    return (
      <>
        <Typography gutterBottom>
          {" "}
          Choose {videoCard ? "video" : "audio"} and image files from your
          computer.
        </Typography>

        <UploadInput setFile={setIconFile} fileType='image/*'>
          Upload Icon
        </UploadInput>
        <UploadInput
          cardPreview={setImagePreview}
          setFile={setImageFile}
          fileType='image/*'>
          Upload Image
        </UploadInput>

        {!videoCard ? (
          <UploadInput
            cardPreview={setSoundPreview}
            setFile={setSoundFile}
            fileType='audio/*'>
            Upload Audio
          </UploadInput>
        ) : (
          <UploadInput
            cardPreview={setVideoPreview}
            setFile={setVideoFile}
            fileType='video/*'>
            Upload Video
          </UploadInput>
        )}

        <Box m={1}>
          <LoadingButton
            disabled={
              uploaded || videoCard
                ? !videoFile || uploaded
                : !soundFile || uploaded
            }
            loading={uploading}
            variant='standard'
            onClick={handleSelfHosted}>
            {" "}
            Upload Files{" "}
          </LoadingButton>
        </Box>
        {uploaded && !videoCard && (
          <Box m={2} p={2}>
            <Typography color='success' gutterBottom>
              {" "}
              Files uploaded successfully! <br></br> You can view a preview at{" "}
              <Link color='inherit' href={html} target='__blank'>
                {html}
              </Link>
            </Typography>
          </Box>
        )}
        {/* <label htmlFor='image-file'>
          <UploadInput accept='image/*' id='image-file' multiple type='file' />
          <LoadingButton variant='contained' component='span'>
            Upload Image
          </LoadingButton>
        </label>

        <label htmlFor='audio-file'>
          <UploadInput accept='audio/*' id='audio-file' multiple type='file' />
          <LoadingButton variant='contained' component='span'>
            Upload Audio
          </LoadingButton>
        </label> */}
      </>
    );
  };
  let timer;
  const checkElement = (url, setter, element = "img") => {
    if (url === "") return;
    timer && clearTimeout(timer);
    timer = setTimeout(async () => {
      try {
        const success = () => {
          showSuccess(
            `${url} is a valid ${element === "img" ? "image" : element} file`
          );
          setter(url);
          return true;
        };
        const tmp = document.createElement(element);
        tmp.src = url;
        if (element === "audio") {
          tmp.onloadedmetadata = success();
        } else {
          tmp.onload = success();
        }

        tmp.onerror = () => {
          showError({
            message: `URL ${url} is not a valid ${
              element === "img" ? "image" : "audio"
            } or destination is unreachable`,
          });

          return false;
        };
      } catch (error) {
        showError({
          message: `URL ${url} is not a valid image or destination is unreachable`,
        });
        return false;
      }

      // showError(error);
    }, 300);
  };

  const handleImageURL = async (imageUrl) => {
    const url = checkElement(imageUrl, setImagePreview);
    //  if it's self hosted, set the file for the JSON to the url, otherwise we need to upload and return the file link
    if (selfHosted) {
      console.log(imageUrl);
      setImagePreview(imageUrl);
      checkElement(imageUrl, setImageFile);
    } else {
      checkElement(imageUrl, setImagePreview);
    }
  };

  const handleIconUrl = async (iconUrl) => {
    checkElement(iconUrl, setIcon);
  };

  const handleAudioURL = async (audioUrl) => {
    if (selfHosted) {
      checkElement(audioUrl, setSoundFile, "audio");
      setSoundPreview(audioUrl);
    } else {
      checkElement(audioUrl, setSoundPreview, "audio");
    }
  };

  return (
    <Paper elevation={3} sx={{ mx: "auto", maxWidth: 1100, my: 4, padding: 4 }}>
      <WelcomeDialog dialog={dialog} showDialog={showDialog}></WelcomeDialog>

      <UploadForm hidden={json}>
        <AssetInstructions></AssetInstructions>
        <FormControl fullWidth margin='normal' size='large'>
          <InputLabel htmlFor='video-select'>Card Type</InputLabel>{" "}
          <Select
            label='card type'
            sx={{ width: "100%" }}
            labelId='video-select-label'
            id='video-select'
            value={videoCard}
            onChange={(e) => {
              setVideoCard(e.target.value);
            }}>
            <MenuItem value={true}>
              Video card (use mp4 file with backup image)
            </MenuItem>
            <MenuItem value={false}>
              Audio Card (.gif, .png .jpg and audio)
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin='normal' size='large'>
          <StyledTextField
            required
            value={name}
            onChange={(e) => {
              if (e.target.value.length < 32)
                setName(e.target.value.toUpperCase().replace(" ", ""));
              else {
                showError({
                  message: "Asset name must be less than 32 letters!",
                });
              }
            }}
            id='asset-name'
            label='Asset Name (Less than 32 letters, UPPERCASE)'
            variant='standard'
          />
          <StyledTextField
            required
            onChange={(e) => {
              const valid = WAValidator.validate(e.target.value, "BTC");

              if (valid) {
                setAddress(e.target.value);
                showSuccess(`${e.target.value} is a valid BTC address `);
              } else {
                showError({ message: "Invalid BTC Address" });
              }
            }}
            id='address'
            label='Owner Address: This is the BTC Address that will own the asset'
            variant='standard'
          />
          <StyledTextField
            required
            value={supply}
            onChange={(e) => {
              setSupply(e.target.value);
              if (isNaN(e.target.value))
                showError("asset supply must be a number");
            }}
            id='supply'
            label='Asset Supply: How many tokens are issued'
            variant='standard'
          />
          {/* FILE URLS */}
          {/* have a select box */}

          <Box
            sx={{
              display: "block",
              width: "100%",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}>
            <Select
              sx={{ width: "100%" }}
              labelId='hosting-select-label'
              id='hosting-select'
              value={selfHosted}
              onChange={(e) => {
                setSelfHosted(e.target.value);
              }}>
              <MenuItem value={true}>I want to host files myself</MenuItem>
              <MenuItem disabled value={false}>
                I want to host files on fakeasf.club servers
              </MenuItem>
            </Select>
          </Box>
          {!uploaded && selfHosted ? selfHostedForm() : uploadForm()}
        </FormControl>

        <LoadingButton
          color={json ? "success" : "secondary"}
          loadingIndicator={!uploaded ? "Uploading..." : "Generating..."}
          loading={uploading}
          hidden={json}
          onClick={() => {
            generateJSON();

            // otherwise upload to AWS and show user the result.
          }}
          disabled={
            videoCard
              ? !videoFile || !imageFile || !name || !icon
              : !soundFile || !imageFile || !name || !icon
          }
          variant='contained'>
          GENERATE CARD
        </LoadingButton>
      </UploadForm>

      {json && (
        <JSONInstructions
          jsonLink={jsonLink}
          json={json}
          name={name}
          previewLink={previewLink}></JSONInstructions>
      )}
      <Collapse in={videoCard ? videoPreview : soundPreview && imagePreview}>
        {videoPreview || (soundPreview && imagePreview) ? (
          <CardPreview
            imageFile={imagePreview}
            soundFile={soundPreview}
            videoFile={videoPreview}
            uploading={uploading}
            setMessage={setMessage}
            icon={icon}></CardPreview>
        ) : (
          <Skeleton variant='rectangular' width={400} height={560} />
        )}
      </Collapse>
    </Paper>
  );
}
