import { useMemo, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/";
import CssBaseline from "@mui/material/CssBaseline";
import { Paper, Snackbar, Alert, Fade } from "@mui/material";
import Nav from "./components/utils/Nav";
import CardForm from "./components/CardForm";

// TODO: replace with multi step form
function App() {
  const theme = useMemo(() => {
    return createTheme({
      palette: {
        mode: "dark",
        primary: {
          main: "#212121",
        },
        secondary: {
          main: "#313131",
        },
        success: {
          main: "#377b37",
        },
      },
    });
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMessage({ open: false });
  };
  const [message, setMessage] = useState({
    open: false,
    type: "success",
    text: "test",
  });

  const showError = (error) => {
    setMessage({
      open: false,
    });

    setTimeout(() => {
      setMessage({
        open: true,
        type: "error",
        text: error.message,
      });
    }, 333);
  };

  const showSuccess = (message) => {
    setMessage({
      open: false,
    });

    setTimeout(() => {
      setMessage({
        open: true,
        type: "success",
        text: message,
      });
    }, 333);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Paper
        style={{ height: "100%", minHeight: "100vh", alignItems: "center" }}>
        <Nav></Nav>
        <CardForm
          showSuccess={showSuccess}
          showError={showError}
          setMessage={setMessage}></CardForm>
        <Snackbar
          TransitionComponent={Fade}
          key={message.text}
          transitionDuration={300}
          open={message.open}
          onClose={handleClose}
          autoHideDuration={6000}>
          <Alert
            open={message.open}
            onClose={handleClose}
            severity={message.type}
            sx={{ mx: "auto", width: "100%" }}>
            {message.text}
          </Alert>
        </Snackbar>
      </Paper>
    </ThemeProvider>
  );
}

export default App;
