import styled from "styled-components";
import { LoadingButton } from "@mui/lab";
import { TextField, Typography } from "@mui/material";

import { useState } from "react";
import { Upload, Check } from "@mui/icons-material";

const StyledInput = styled.input`
  display: none;
`;

const UploadContainer = styled.div``;
const UploadLabel = styled.label`
  margin-bottom: 0.5rem;
  height: 3rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: stretch;
`;

const UploadText = styled(Typography)`
  margin-left: 1rem;
`;

const PreviewImage = styled.img`
  justify-self: flex-end;
  max-height: 2rem;
  max-width: 2rem;
  margin: 0 1rem;
`;
// styled upload form, has a label with textfield showing the filename (read only) and a button to upload files. Also shows loading?
// also has state for filename and if the input has been aborted

export default function UploadInput(props) {
  const [fileName, setFilename] = useState("");

  const [preview, setPreview] = useState("");
  const [loading, setLoading] = useState(false);

  const { fileType, id, children, showError, cardPreview, setFile } = props;

  const handleChange = (e) => {
    !e.target.files[0] && console.error(e);
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
    handlePreview(e.target.files[0]);
  };

  const handlePreview = (file) => {
    if (file) {
      const url = URL.createObjectURL(file);
      setPreview(url);
      cardPreview && cardPreview(url);
    } else {
      showError({ message: "File not valid" });
    }
  };
  return (
    <UploadContainer>
      <UploadLabel htmlFor={id}>
        <StyledInput
          accept={fileType}
          id={id}
          type='file'
          onChange={(e) => handleChange(e)}
        />
        <LoadingButton
          startIcon={<Upload></Upload>}
          loading={loading}
          variant='contained'
          component='span'>
          {children}
        </LoadingButton>
        <UploadText>{fileName}</UploadText>

        {fileType && fileType.includes("image") && (
          <PreviewImage src={preview}></PreviewImage>
        )}

        {fileName && <Check color='success'></Check>}
      </UploadLabel>
    </UploadContainer>
  );
}
