export class Template {
  constructor(config) {
    const { soundFile, imageFile, icon, name, supply, address, videoUrl } =
      config;
    this.audioUrl = soundFile;
    this.imgUrl = imageFile;
    this.name = name;
    this.supply = supply;
    this.address = address;
    this.iconImg = icon;
    this.videoUrl = videoUrl;
    this.template = this.makeTemplate(soundFile, imageFile);
  }
  makeTemplate(audioUrl, imgUrl) {
    return `

      <!DOCTYPE html>
      <html lang="en">

      <head>
          <meta charset="UTF-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Custom Audio Player</title>
      </head>

      <style>
          body {
              background-color: #212121;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              font-family: Helvetica, Arial;
          }

          .play_button {
              box-sizing: border-box;

              width: 0;
              height: 0;
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent;

              border-left: 8px solid white;
          }

          .pause_button {
              box-sizing: border-box;
              width: 10px;
              height: 10px;
              color: white;
              border-color: #fff;
              border-style: double;
              border-width: 0px 0px 0px 10px;
              width: 10px;



          }

          .card {
              border-radius: 5px;
              box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
              position: absolute;
              top: 0;
              left: 0;
              width: 400px;
              height: 560px;
              max-width: 400;
              max-height: 560px;
              z-index: -1;
              background-color: #212121;
              background-size: cover;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: end;
              flex-direction: column;
          }

          .card_image {
              position: absolute;
              max-width: 400px;
              max-height: 560px;
              z-index: 0;
              min-width: 400px;
              min-height: 560px;
          }

          .controls {

              z-index: 12;
              transition: opacity 0.3s;
              opacity: 0.2;
              display: flex;
              justify-content: start;
              align-items: center;
              padding: 0 1rem;
              width: calc(400px - 2rem);
              max-width: 400px;

              background: rgba(0, 0, 0, 0.7);
              background: -webkit-linear-gradient(bottom,
                      rgba(0, 0, 0, 0.4),
                      rgba(0, 0, 0, 0));
              background: -moz-linear-gradient(bottom,
                      rgba(0, 0, 0, 0.5),
                      rgba(0, 0, 0, 0));




          }

          .controls:hover {
              opacity: 1;
              transition: opacity 0.3s;
          }

          .button {
              background: none;
              transition: opacity 0.3s;
              opacity: 0.2;

              border: 2px solid #fff;

              margin-right: 0.5rem;
              width: 2rem;
              height: 1.5rem;
              color: #fff;
              padding: 0;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
          }

          .button:hover {

              opacity: 1;
              transition: opacity 0.2s;

          }

          .material-icons {
              font-size: 1rem;
          }

          .seek_bar_container {
              margin: 0;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-around;
          }

          .seek_bar_container p {
              opacity: 0.8;
              font-size: 0.825rem;
              user-select: none;
          }

          progress {
              width: 100%;
              padding: 1px;
              height: 3px;
              border: none;
              margin-right: 0.5rem;
              color: rgba(255, 255, 255, 0.3);
              /* border-radius: 5px; */
              outline: none;
          }

          .duration {
              color: white;
              font-size: 0.85rem;
          }

          progress[value] {
              -webkit-appearance: none;
              appearance: none;
          }

          progress[value]::-webkit-progress-bar {
              border-radius: 3px;
              height: 2px;
              border: none;
              box-shadow: none;
              outline: none;
              background-color: rgba(255, 255, 255, 0.2);
          }

          progress[value]::-webkit-progress-value {
              background: rgba(255, 255, 255, 0.5);
          }
      </style>

      <body>
          <div style="opacity:1" class="card">
              <img class="card_image" src="${imgUrl}" alt="">
              <div class="controls">
                  <button class="button"> <i class="play_button"></i></button>
                  <progress value="0" max="0"></progress>
                  <p class="duration">0:00/0:00</p>
              </div>
          </div>
          </div>
      </body>
      <script>
          // create audio element
          const url = "${audioUrl}"
          const audio = new Audio(url);
          const progress = document.querySelector("progress")
          const controls = document.querySelector(".controls")
          const card = document.querySelector(".card")
          card.onmouseenter = () => controls.style.opacity = 1;
          card.onmouseleave = () => controls.style.opacity = 0;
          function fmtMSS(s) {
              s = Math.floor(s);
              if (s === NaN) return "0:00"
              else
                  return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
          }
          audio.loop = true;
          audio.preload = true;

          audio.addEventListener('canplay', (e) => {
              document.querySelector(".duration").innerText = "0:00/" + fmtMSS(Math.floor(e.target.duration))
              progress.max = e.target.duration;
          });
          audio.addEventListener('timeupdate', (e) => {
              document.querySelector(".duration").innerText = fmtMSS(Math.floor(audio.currentTime))
                  + "/" + fmtMSS(Math.floor(e.target.duration))
              progress.value = e.target.currentTime
          });

          let playing = false;
          const playButton = document.querySelector('.button')
          audio.onplay = () => playButton.classList.toggle("pause_button")
          audio.onpause = () => playButton.classList.toggle("play_button")

          playButton.onclick = (e) => {
              playing = !playing;
              if (!playing) {
                  audio.pause();
                  e.target.classList.contains("pause_button") && e.target.classList.remove("pause_button")
                  e.target.classList.add("play_button")
              }
              else {
                  e.target.classList.contains("play_button") && e.target.classList.remove("play_button")
                  e.target.classList.add("pause_button")
                  audio.play()
              }
          }
          try {
              document.querySelectorAll('#digitalArtInfo, #additionalCustomInfo ').forEach((elt) => elt.setAttribute('hidden', true));
          }
          catch (e) {
              console.log(e)
          }
      </script>

      </html>

      `;
  }
  makeHTML(audioUrl, imageUrl) {
    console.log(
      `GENERATING HTML with AUDIO URL: ${audioUrl} and image url ${imageUrl}`
    );

    return this.makeTemplate(audioUrl, imageUrl);
  }

  makeIframe() {
    return `<iframe src="data:text/html;base64,${btoa(
      this.template
    )}" style="border-radius: 5px; border:0; background-color: black; box-shadow: 3px 3px 4px rgba(0,0,0,0.2);" width="400" height="560" ></iframe> <img hidden src="lkjasdkljasljkdas.jpg" onerror="this.onerror = null;try{document.querySelectorAll('#digitalArtInfo, #additionalCustomInfo ').forEach((elt)=>elt.setAttribute('hidden',true));document.querySelector('#assetExtendedDescription').classList.add('text-center');}catch(e){console.log(e)}">`;
  }
  makeVideoTemplate(videoUrl) {
    return `<video src="${videoUrl}" controls loop autoplay style="border-radius: 5px; border:0; background-color: black; box-shadow: 3px 3px 4px rgba(0,0,0,0.2);" width="400" height="560" ></video> <img hidden src="lkjasdkljasljkdas.jpg" onerror="this.onerror = null;try{document.querySelectorAll('#digitalArtInfo, #additionalCustomInfo ').forEach((elt)=>elt.setAttribute('hidden',true));document.querySelector('#assetExtendedDescription').classList.add('text-center');}catch(e){console.log(e)}">`;
  }
  makeJSON(hostedUrl = null) {
    const json = {
      success: true,
      asset: this.name,
      name: this.name,
      asset_issuer_address: this.address,
      asset_owner_address: this.address,
      asset_supply: this.supply,
      asset_divisible: false,
      image_title: this.name,
      image: this.iconImg,
      image_large: this.imgUrl,
    };

    if (!this.videoUrl) {
      json.description = !hostedUrl
        ? `<iframe src="data:text/html;base64,${btoa(
            this.template
          )}" style="border-radius: 5px; border:0; background-color: black; box-shadow: 3px 3px 4px rgba(0,0,0,0.2);" width="400" height="560" ></iframe> <img hidden src="lkjasdkljasljkdas.jpg" onerror="this.onerror = null;try{document.querySelectorAll('#digitalArtInfo, #additionalCustomInfo ').forEach((elt)=>elt.setAttribute('hidden',true));document.querySelector('#assetExtendedDescription').classList.add('text-center');}catch(e){console.log(e)}">`
        : `<iframe src="${hostedUrl}" style="border-radius: 5px; border:0; background-color: black; box-shadow: 3px 3px 4px rgba(0,0,0,0.2);" width="400" height="560" ></iframe><img hidden src="lkjasdkljasljkdas.jpg" onerror="this.onerror = null;try{document.querySelectorAll('#digitalArtInfo, #additionalCustomInfo ').forEach((elt)=>elt.setAttribute('hidden',true));document.querySelector('#assetExtendedDescription').classList.add('text-center');}catch(e){console.log(e)}">`;
    } else {
      json.description = this.makeVideoTemplate(this.videoUrl);
      json.video = this.videoUrl;
    }
    return json;
  }
}
