import { Typography, Divider, Link } from "@mui/material";

export default function AssetInstructions(props) {
  return (
    <div>
      <Typography>
        Enter your asset details here. Make sure these match with your asset
        details in counterwallet or freewallet.
      </Typography>

      <Typography gutterBottom>
        If you want to host the files yourself, you could try{" "}
        <Link color='inherit' target='_blank' href='https://www.pinata.cloud/'>
          pinata{" "}
        </Link>
        or{" "}
        <Link color='inherit' target='_blank' href='https://nft.storage/'>
          nft.storage
        </Link>{" "}
        for audio files and
        <Link color='inherit' target='_blank' href='https://imgur.com/'>
          {" "}
          Imgur
        </Link>{" "}
        for images.
      </Typography>
    </div>
  );
}
