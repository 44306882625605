import PlayArrowIcon from "@mui/icons-material/PlayArrow";
// import StopIcon from "@mui/icons-material/Stop";
import PauseIcon from "@mui/icons-material/Pause";
import ReactAudioPlayer from "react-audio-player";

import { useState, useRef, useEffect } from "react";
import {
  Card,
  PlaceHolder,
  CardImage,
  Controls,
  PlayButton,
  SeekBarContainer,
  SeekBar,
} from "./utils/AudioPlayerStyles";

export default function AudioPlayer({ imageSrc, soundSrc, setMessage }) {
  const [playing, setPlaying] = useState(false);
  const [controls, showControls] = useState(true);
  const audioElement = useRef();
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  function fmtMSS(s) {
    return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
  }

  useEffect(() => {
    try {
      const player = audioElement.current.audioEl.current;
      player.addEventListener("canplay", (e) => {
        console.log(e.target);
        setDuration(Math.floor(e.target.duration));
      });

      player.addEventListener("timeupdate", (e) => {
        setCurrentTime(Math.floor(e.target.currentTime));
        setProgress(e.target.currentTime);
      });
    } catch (error) {
      setMessage({
        open: true,
        type: "error",
        text: error,
      });
    }
  }, [setMessage]);
  const handlePlaying = (playing) => {
    try {
      const player = audioElement.current.audioEl.current;
      setDuration(Math.floor(player.duration));
      if (player) {
        if (playing) {
          try {
            player.play();
          } catch (error) {
            setMessage({
              open: true,
              type: "error",
              text: error,
            });
          }
        } else {
          player.pause();
        }
        setPlaying(playing);
      }
    } catch (error) {
      setMessage({
        open: true,
        type: "error",
        text: error,
      });
    }
  };

  return (
    <Card
      styles={{ backgroundImage: `url(${imageSrc})` }}
      onMouseEnter={() => showControls(true)}
      onMouseLeave={() => showControls(false)}>
      <PlaceHolder variant='h1'></PlaceHolder>
      <CardImage src={imageSrc}></CardImage>
      <Controls style={{ opacity: controls ? 1.0 : 0.0 }}>
        <PlayButton
          onClick={() => {
            handlePlaying(!playing);
          }}>
          {!playing ? (
            <PlayArrowIcon fontSize='small'></PlayArrowIcon>
          ) : (
            <PauseIcon fontSize='small'></PauseIcon>
          )}
        </PlayButton>

        {/* <StopButton onClick={() => handlePlaying(!playing)} href='' title=''>
          <StopIcon fontSize='small'></StopIcon>
        </StopButton> */}
        <SeekBarContainer onClick={(e) => console.log(e)}>
          <SeekBar value={progress} max={duration} />
          {
            <p>
              {fmtMSS(currentTime)}/{fmtMSS(duration)}
            </p>
          }
        </SeekBarContainer>
      </Controls>

      <ReactAudioPlayer
        ref={audioElement}
        src={soundSrc}
        loop={true}
        timeupdate={(e) => {
          setCurrentTime(e);
        }}
        onSeeked={(e) => setProgress(e)}></ReactAudioPlayer>
    </Card>
  );
}
