import {
  Grid,
  Card,
  CardHeader,
  Icon,
  Box,
  CardContent,
  Collapse,
} from "@mui/material";
import AudioPlayer from "./AudioPlayer";
import styled from "styled-components";

const VideoPlayer = styled.video`
  border-radius: 5px;
  border: 0;
  background-color: black;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
`;
export default function CardPreview({
  imageFile,
  soundFile,
  uploading,
  setMessage,
  icon,
  videoFile,
}) {
  return (
    <div>
      <Box m={2}></Box>
      <Grid
        container={true}
        alignItems='center'
        justifyContent='center'
        direction='column'>
        <CardHeader
          subheader={
            <CardContent>
              This is a preview of how your card will look on xchain.io and in
              Freewallet. <br></br>
              <strong>Please check this </strong> to make sure it looks and
              sounds right before you generate the card.
            </CardContent>
          }
          avatar={<Icon src={icon}></Icon>}
        />

        <Card sx={{ width: 400, height: 560, margin: 2 }} padding={2}>
          {!videoFile ? (
            <AudioPlayer
              imageSrc={imageFile}
              soundSrc={soundFile}
              uploading={uploading}
              setMessage={setMessage}></AudioPlayer>
          ) : (
            <VideoPlayer
              src={videoFile}
              controls
              autoplay
              width='400'
              height='560'></VideoPlayer>
          )}
        </Card>

        <Grid item></Grid>
      </Grid>
    </div>
  );
}
